/**
Template Name: Material Able Admin Template
Author: #
Email: #
File: style.css
=============
  == Table of Contents==
	- Custom Css
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, pagination, Basic card
	- General Elements
		-	Lable & Badges, Materialize general elements, Lables
	- Pages
		-   Progress bar, Chat , dark layout
============= */
// General theme contents

@use 'sass:meta';
@use 'bootstrap' as *;

@use 'partials/variables';
@use 'partials/mixins';
@use 'partials/general';
@use 'partials/generic';

// Menu
@use 'partials/menu/menu';

// Important Theme elements
@use 'partials/theme-elements/button';
@use 'partials/theme-elements/form-elements';

// Theme elements
@use 'partials/theme-elements/label-badges';

// Tables
@use 'partials/tables/tables';

// Other
@use 'partials/other/chat';
@use 'partials/other/progress-bar';

// Responsive css
@use 'partials/responsive/responsive';

// dark layout css
// dark layout css
@use 'partials/dark';

//angular custom
@use 'partials/custom';

@use 'partials/menu/horizontal-menu';

// regular style toast
@import '~ngx-toastr/toastr.css';

@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

@import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeicons/primeicons.css';

@include meta.load-css('~ngx-ui-switch/ui-switch.component.scss');
@include meta.load-css('archwizard');

.form-control.builder-sidebar_search {
  display: none;
}
