@use 'colors';

.scw-modal-base {
  .modal-dialog {
    .modal-content {
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      border: unset;

      .modal-header {
        &.scw-modal-header {
          height: 68px !important;
          background: #53585f !important;
          border-bottom: 4px solid colors.$accent-color !important;
          color: #fff !important;
          padding: 16px 24px !important;

          .close {
            padding: 20px 20px !important;
            font-size: 17px !important;
            text-shadow: initial !important;
          }
        }
      }

      .modal-body {
        @extend .modal-main-padding;
      }

      .modal-footer {
        @extend .modal-main-padding;
        overflow: unset;

        .buttons-left {
          margin-right: auto;
        }

        .buttons-left, .buttons-right {
          scw-mat-button:not(:last-child) button {
            margin-right: 10px;
          }
        }
      }

      .modal-section {
        display: flex;
        flex-direction: row;
      }

      .modal-info-icon {
        font-size: 22px;
        color: #666666;
        padding-right: 7px;
      }
    }
  }
}

.modal {
  &.scw-modal-sm:not(.scw-modal-all-scrollable),
  &.scw-modal-md:not(.scw-modal-all-scrollable),
  &.scw-modal-lg:not(.scw-modal-all-scrollable),
  &.scw-modal-xl:not(.scw-modal-all-scrollable)  {
    .modal-dialog .modal-content {
      max-height: calc(100vh - 3.8em);

      .modal-body {
        overflow-y: auto;
      }

      formio figure.table {
        overflow-y: scroll;

        td {
          white-space: normal;
          word-wrap: break-word;
        }
      }
    }
  }

  &.scw-modal-sm {
    @extend .scw-modal-base;

    .modal-dialog {
      .modal-content {
        width: 472px !important;
      }
    }
  }

  &.scw-modal-md {
    @extend .scw-modal-base;

    .modal-dialog {
      @media (min-width: 576px) {
        max-width: 720px !important;
      }

      .modal-content {
        width: 720px !important;
      }
    }
  }

  &.scw-modal-lg {
    @extend .scw-modal-base;
    padding: 0 40px;

    .modal-dialog {
      @media (max-width: 800px) {
        max-width: 100% !important;
      }

      max-width: 832px;
    }
  }

  &.scw-modal-xl {
    @extend .scw-modal-base;
    padding: 0 40px;

    .modal-dialog {
      width: 100% !important;
      max-width: none !important;

      .modal-content {
        width: 100% !important;
      }
    }
  }

  &.scw-modal-erp {
    @extend .scw-modal-base;

    .modal-dialog {
      @media (min-width: 1200px) {
        max-width: 1050px !important;
      }

      @media (max-width: 1200px) {
        max-width: 900px !important;
      }
    }
  }

  .table-responsive {
    overflow-y: hidden;
  }
}

.modal-main-padding {
  padding: 12px 24px !important;
}


.scw_mat-add_edit_modal {

  &-body {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-checkbox {
      flex-basis: 24px;
      margin-right: 16px;
      margin-top: 32px
    }

    &-input {
      flex: 1;
    }

    &-select-input {
      flex: 1;
      width: 80%;
    }
  }
}
.dropdown-list ul {
  overflow: hidden !important;
}

.scw_mat-add_edit_modal-item-select-input{
  .lazyContainer{
    word-break: break-word;
    white-space: normal;
  }
  .cuppa-dropdown > div > div > span > span {
    display: inline-block;
    max-width: 314px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
