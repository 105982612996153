@use "sass:color";
@use "sass:list";
@use "variables";

.pcoded[layout-type="dark"] {
    color: variables.$light-color-text;

    a {
        color: variables.$light-color-text;
    }

    text {
        fill: variables.$light-color-text;
    }

    .card {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);

        code {
            background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
            color: variables.$light-color-text;
        }

        code[class*="language-"],
        pre[class*="language-"] {
            color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
        }

        .card-header {
            h5 {
                color: variables.$light-color-text;
            }

            .card-header-right i {
                color: variables.$light-color-text;
            }
        }

        .card-block {
            code {
                background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);

                &.language-markup {
                    background-color: #fff;
                }
            }

            .dropdown-menu {
                background-color: color.adjust(variables.$dark-layout-color,$lightness: 7%);
            }
        }
    }

    .card-footer {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 3%);
    }

    .dropdown-menu {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 7%);

        .dropdown-item {
            .dropdown-divider {
                border-top: 1px solid variables.$dark-layout-color;
            }

            &:focus,
            &:hover {
                background-color: variables.$dark-layout-color;
            }
        }
    }

    .sub-title {
        color: color.adjust(variables.$dark-layout-color,$lightness: 20%);
    }

    .b-l-default,
    .b-l-theme {
        border-left: 1px solid color.adjust(variables.$dark-layout-color,$lightness: 10%);
    }

    .b-r-default,
    .b-r-theme,
    .messages-content {
        border-right: 1px solid color.adjust(variables.$dark-layout-color,$lightness: 10%);
    }

    .btn-default {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
        color: variables.$light-color-text;
    }
    .pcoded-main-container {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
    }
    &[vertical-layout="box"],
    &[theme-layout="vertical"][vertical-nav-type="collapsed"]{
        .pcoded-container {
            background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
        }
    }

    .progress {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
    }

    .alert {
        &.alert-danger,
        &.alert-default,
        &.alert-info,
        &.alert-primary,
        &.alert-success,
        &.alert-warning {
            background-color: variables.$dark-layout-color;
        }
        $alert-name: default, primary, success, info, warning, danger;
        $alert-color: variables.$default-color, variables.$primary-color, variables.$success-color, variables.$info-color, variables.$warning-color, variables.$danger-color;
        @each $var in $alert-name {
            $i: list.index($alert-name, $var);

            &.background-#{$var} {
                background-color: list.nth($alert-color, $i);
                color: #fff;
            }
        }
    }

    .accordion-msg {
        color: variables.$light-color-text;
        border-top: 1px solid color.adjust(variables.$dark-layout-color,$lightness: 10%);
    }

    .accordion-desc {
        color: variables.$light-color-text;
    }

    .header-navbar {
        .navbar-wrapper {
            .navbar-container {
                .nav-right > .header-notification:nth-child(1) {
                    .show-notification {
                        li:first-child:hover {
                            background-color: variables.$dark-layout-color !important;
                        }
                    }
                }

                .header-notification {
                    .profile-notification,
                    .show-notification {
                        color: variables.$light-color-text;
                        background-color: variables.$dark-layout-color;

                        &.show:before {
                            border-left-color: variables.$dark-layout-color;
                            border-top-color: variables.$dark-layout-color;
                        }

                        a {
                            color: variables.$light-color-text;
                        }
                    }
                }

                .header-notification {
                    .profile-notification li:hover,
                    .show-notification li:hover {
                        background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
                    }
                }
            }
        }
    }

    .p-chat-user {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);

        .userlist-box {
            border-bottom: 1px solid color.adjust(variables.$dark-layout-color,$lightness: 10%);

            .chat-header {
                color: variables.$light-color-text;
            }
        }
    }

    .back_chatBox,
    .back_friendlist {
        i {
            background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);
            color: variables.$light-color-text;
        }
    }

    .showChat_inner {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);

        .chat-messages {
            .chat-menu-content > div {
                background-color: variables.$dark-layout-color;

                &:before {
                    border-right-color: variables.$dark-layout-color;
                    border-top-color: variables.$dark-layout-color;
                }
            }

            .chat-menu-reply > div {
                background-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);

                &:before {
                    border-left-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
                    border-bottom-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
                }
            }
        }

        .chat-inner-header {
            border-bottom: 1px solid variables.$dark-layout-color;
        }

        .chat-reply-box {
            background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);
            border-top: 1px solid variables.$dark-layout-color;
        }
    }

    .form-control {
        background-color: transparent;
        color: variables.$light-color-text;
    }

    select.form-control {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);
    }

    .bg-white {
        background: color.adjust(variables.$dark-layout-color,$lightness: 5%) !important;
    }

    .card-header-img {
        h5,
        h6 {
            color: variables.$light-color-text;
        }
    }

    .text-inverse {
        color: variables.$light-color-text !important;
    }

    #styleSelector {
        color: variables.$dark-layout-color;

        .form-control {
            color: variables.$dark-layout-color;
        }
    }

    .chat-card .chat-control .chat-form .form-control {
        background-color: transparent;

        &:active,
        &:focus {
            box-shadow: none;
        }
    }

    .table {
        > thead > tr > th {
            border-bottom-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
        }

        td,
        th {
            border-top-color: color.adjust(variables.$dark-layout-color,$lightness: 10%);
        }
    }

    .main-menu .main-menu-header:before,
    .page-header:before {
        background-color: color.adjust(variables.$dark-layout-color,$lightness: 5%);
    }
    @each $value in variables.$color-name {
        $i: list.index(variables.$color-name, $value);

        .bg-#{$value} {
            background: list.nth(variables.$color-color,$i),;
        }
    }

}
