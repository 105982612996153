// -------- UI Colors -----------
$dark-color: #4c5054;
$dark-2-color: #62676a;
$light-color: #ecf0f5;
$accent-color: #ef5323;
$primary-color: #448aff;
$white: #ffffff;
$black: #000000;
$muted: #53585f;

// ----- Semantic Colors --------
$planned-down-time-color: #ff726b;
$rejected: #dd332b;
$attention-color: #ff9347;
$awaiting-color: #fac300;
$active: #6fc764;
$idle-time-color: #bdbdbd;
$in-progress-color: #ffd600;
$busy-color: $planned-down-time-color;
$available-color: $active;
$draft: #333333;
$obsolete: #aeb0b2;
$canceled: #aeb0b2;
