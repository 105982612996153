@use 'colors';
@use 'typography';
@use 'modals';
@use 'ui-elements';


ul.scw-basic-list {
  font-family: typography.$scw-font-family !important;
  list-style-type: unset;
  padding-left: 1.5rem;

  .inner-scw-basic-list {
    list-style-type: circle !important;
    padding-left: 1rem;
  }
}

.inner-form-section {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 1px solid #a8a8a8;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-background-clip: padding-box;
  -moz-background-clip:    padding;
  background-clip:         padding-box;
}

label.scw-mat-other-input-label {
  font-family: typography.$scw-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
  margin-bottom: 8px !important;
}
