.br-horizontal .br-unit.br-active,
.br-horizontal .br-unit.br-selected,
.br-movie .br-unit.br-active,
.br-movie .br-unit.br-selected,
.br-vertical .br-unit.br-active,
.br-vertical .br-unit.br-selected {
  background-color: #449bff !important;
}

.br-square .br-unit.br-active,
.br-square .br-unit.br-selected {
  border: 2px solid #449bff !important;
  color: #449bff !important;
}

.br-fontawesome .br-selected:after,
.br-default .br-selected:after,
.br-fontawesome-o .br-selected:after,
.br-fontawesome-o .br-fraction:after {
  color: #449bff !important;
}

.br-horizontal .br-unit,
.br-movie .br-unit,
.br-vertical .br-unit {
  background-color: rgba(114, 180, 255, 0.4) !important;
}

.br-square .br-unit {
  border: 2px solid rgba(114, 180, 255, 0.4) !important;
}

.stars .title {
  top: -5px;
}
