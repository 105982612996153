.small-icon .icon {
  width: auto !important;
  height: auto !important;
}

.no-icon .icon {
  display: none;
}

.simple-notification-wrapper {
  z-index: 10001 !important;
  width: auto !important;
  max-width: 450px;
}
